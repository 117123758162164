import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll'; 
import PlayVideo from '../PlayVideo/PlayVideo';
import "./LocalArea.scss"
import { GetURL, getVideoId } from "../utils";
import { isMobile, isDesktop } from "react-device-detect";
import HTMLReactParser from "html-react-parser";
// Header component

const LocalArea = (props) => {
    const [isPlay, setPlay] = useState(false);
    return (
        <React.Fragment>
            <section className="marketing-services">
                <Container>
                    <Row className="flex-column-reverse flex-lg-row">
                        <Col lg="6">
                            <ScrollAnimation animateIn="fadeInLeft">
                                <div className="services-content">
                                    <span className="sub-title">{props?.data?.Sm_Title}</span>
                                    <h2>{props?.data?.Title}</h2>
                                    {/* <p>{props?.data?.Description}</p> */}
                                    {props?.data?.Content && <p>{HTMLReactParser(props?.data?.Content)}</p>}
                                    <Link to={GetURL(props?.data?.About_Cta_Link?._id)} className="btn btn-outline">{props?.data?.Cta_Label}</Link>
                                </div>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="6">
                            <ScrollAnimation animateIn="fadeInRight">
                                <picture className="service-img img-zoom">
                                    <img src={props?.data?.Tile_Image?.url} alt="Services Img" onClick={()=> {setPlay(true)}} />
                                    {(props?.data?.Video_Url && !isPlay) && <button type="button" className="play-btn" onClick={() => { setPlay(true) }}>
										<i className="video-icon"></i>
									</button>}
                                    {(props?.data?.Video_Url && isPlay) && <div className="inline-video inline-video-container">
                                        {isPlay && 
                                            <React.Fragment>
                                                <div className="d-block">
                                                    <PlayVideo
                                                        isOpen={isPlay}
                                                        videoId={props?.data?.Video_Url}
                                                        stopPlay={setPlay}
                                                        width={'100%'} height={'100%'}
                                                        fullscreen={true}
                                                        className={"inline-video"}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>}
                                </picture>
                            </ScrollAnimation>
                          </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default LocalArea
